import {
    ACTIVE_COMPANY,
    CLOSE_COMPANY_DRAWER,
    OPEN_COMPANY_DRAWER,
} from '../../constants/ActionTypes'


const INIT_STATE = {
    companyDrawer: false,
    activeCompany: null,


};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OPEN_COMPANY_DRAWER:
            return {
                ...state,
                companyDrawer: true,

            }

        case CLOSE_COMPANY_DRAWER:
            return {
                ...state,
                companyDrawer: false,

            }

        case ACTIVE_COMPANY: {
            return {
                ...state,
                activeCompany: action.payload,
            }
        }




        default:
            return state;
    }
}
