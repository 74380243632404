import {
    BANK_INFORMATION, EMPLOYEE_RELATIONSHIPS,
    HIRING_INFORMATION,
    PERSONAL_INFORMATION,
    RESET_EMPLOYEE, RESET_ONBOARDING, SELECTED_DOWNLOADS, SELECTED_EMPLOYEE, SELECTED_TASKS, SELECTED_UPLOADS,
    VARIATION_FILE, VARIATION_INFORMATION
} from "../../constants/ActionTypes";


export const setPersonalInformation = (data) => {
    return {
        type: PERSONAL_INFORMATION,
        payload: data
    };
}

export const setTheSelectedTasks = (data) => {
    return {
        type: SELECTED_TASKS,
        payload: data
    };
}

export const setUploads = (data) => {
    return {
        type: SELECTED_UPLOADS,
        payload: data
    };
}

export const setDownloads = (data) => {
    return {
        type: SELECTED_DOWNLOADS,
        payload: data
    };
}

export const resetOnboarding = () => {
    return {
        type: RESET_ONBOARDING,

    };
}

export const setHiringInformation = (data) => {
    return {
        type: HIRING_INFORMATION,
        payload: data
    };
}

export const setBankingInformation = (data) => {
    return {
        type: BANK_INFORMATION,
        payload: data
    };
}


export const setVariationFile = (data) => {
    return {
        type: VARIATION_FILE,
        payload: data
    };
}

export const setVariationInformation = (data) => {
    return {
        type: VARIATION_INFORMATION,
        payload: data
    };
}

export const setSelectedEmployee = (data) => {
    return {
        type: SELECTED_EMPLOYEE,
        payload: data
    };
}

export const resetEmployeeInformation = () => {
    return {
        type: RESET_EMPLOYEE,
    };
}


export const setRelationships = (data) => {
    return {
        type: EMPLOYEE_RELATIONSHIPS,
        payload: data
    };
}



