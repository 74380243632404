import {
    PAYROLL_INFORMATION, PAYROLL_ITEMS, PAYROLL_PERIOD, PENDING_PAYMENT, RESET_PAYROLL,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    payrollInformation: null,
    payrollPeriod: null,
    pendingPayment: [],
    processingItems: [],

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PAYROLL_INFORMATION: {
            return {
                ...state,
                payrollInformation: action.payload,
            }
        }

        case PAYROLL_PERIOD: {
            return {
                ...state,
                payrollPeriod: action.payload,
            }
        }
        case PENDING_PAYMENT: {
            return {
                ...state,
                pendingPayment: action.payload,
            }
        }
        case PAYROLL_ITEMS: {
            return {
                ...state,
                processingItems: action.payload,
            }
        }

        case RESET_PAYROLL: {
            return {
                ...state,
                processingItems: null,
                pendingPayment: null,
                payrollPeriod: null,
            }
        }


        default:
            return state;
    }
}
