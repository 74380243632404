import {
    BASIC_INFORMATION, HIRING_INFORMATION,
    ONBOARDING_INFORMATION,
    RESET_CANDIDATE, SAVE_COUNTRIES, SELECTED_CANDIDATE,

} from "../../constants/ActionTypes";


export const setBasicInformation = (data) => {
    return {
        type: BASIC_INFORMATION,
        payload: data
    };
}

export const setCandidateHiringInformation = (data) => {
    return {
        type: HIRING_INFORMATION,
        payload: data
    };
}

export const setOnboardingInformation = (data) => {
    return {
        type: ONBOARDING_INFORMATION,
        payload: data
    };
}


export const setSelectedCandidate = (data) => {
    return {
        type: SELECTED_CANDIDATE,
        payload: data
    };
}

export const saveCountries = (data) => {
    return {
        type: SAVE_COUNTRIES,
        payload: data
    };
}

export const resetCandidateInformation = () => {
    return {
        type: RESET_CANDIDATE,
    };
}



