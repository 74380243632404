import {
    BASIC_INFORMATION,
    HIRING_INFORMATION, ONBOARDING_INFORMATION, RESET_CANDIDATE, SAVE_COUNTRIES,
    SELECTED_CANDIDATE
} from "../../constants/ActionTypes";

const INIT_STATE = {
    basicInformation: null,
    hiringInformation: null,
    onboardingInformation: null,
    selectedCandidate: null,
    countries:[],
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BASIC_INFORMATION: {
            return {
                ...state,
                basicInformation: action.payload,
            }
        }

        case HIRING_INFORMATION: {
            return {
                ...state,
                hiringInformation: action.payload,
            }
        }
        case ONBOARDING_INFORMATION: {
            return {
                ...state,
                onboardingInformation: action.payload,
            }
        }

        case RESET_CANDIDATE: {
            return {
                ...state,
                basicInformation: null,
                hiringInformation: null,
                onboardingInformation: null,
            }
        }


        case SELECTED_CANDIDATE: {
            return {
                ...state,
                selectedCandidate: action.payload,
            }
        }
        case SAVE_COUNTRIES: {
            return {
                ...state,
                countries: action.payload,
            }
        }


        default:
            return state;
    }
}
